<template>
    <div class="w-full py-4">
        Kivid Network
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'KividNetwork',
    data() {
        return {
            firstName: '',
            lastName: '',
            password: '',
            passwordConfirm: '',
            email: ''
        };
    },
    computed: {
        ...mapGetters('auth', ['loggedIn'])
    },
    methods: {}
};
</script>

<style></style>
